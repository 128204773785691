import React from 'react'
import less from '../images/technologies/tech/less.png'
import sass from '../images/technologies/tech/sass.png'
import foundation  from '../images/technologies/tech/foundation.png'
import react from '../images/technologies/tech/react.png'
import jquery from '../images/technologies/tech/jquery.png'
import python from '../images/technologies/tech/python.png'
import php from '../images/technologies/tech/php.png'
import nodejs from '../images/technologies/tech/node.png'
import laravel from '../images/technologies/tech/laravel.png'
import reactnative from '../images/technologies/tech/reactnative.png'
import mysql from '../images/technologies/tech/mysql.png'
import mongodb from '../images/technologies/tech/mongodb.png'
import mariadb from '../images/technologies/tech/mariadb.png'
import postgresql from '../images/technologies/tech/postgresql.png'
import awsrds from '../images/technologies/tech/awsrds.png'
import redis from '../images/technologies/tech/redis.png'
import sqlite from '../images/technologies/tech/sqlite.png'
import woocommerce from '../images/technologies/tech/woocommerce.png'
import bagisto from '../images/technologies/tech/bagisto.png'
import opencart from '../images/technologies/tech/opencart.png'
import shopify from '../images/technologies/tech/shopify.png'
import strapi from '../images/technologies/tech/strapi.png'
import ghost from '../images/technologies/tech/ghost.png'
import directus from '../images/technologies/tech/directus.png'
import aws from '../images/technologies/tech/aws.png'
import azure from '../images/technologies/tech/azure.png'
import gcp from '../images/technologies/tech/gcp.png'
import chatgpt from '../images/technologies/tech/chatgpt.png'
import bootstrap from '../images/technologies/tech/bootstrap.png'
import vuejs from '../images/technologies/tech/vuejs.png'
import flask from '../images/technologies/tech/flask.png'
import flutter from '../images/technologies/tech/flutter.png'





import synology from '../images/technologies/tech-synology-8403c192e3fbfd5fc9157fa6005f126d.png'
import cisco from '../images/technologies/tech-cisco-6afab7c941a2121f27e5a69ab46601a1.png'
import fortinet from '../images/technologies/fortinet.png'
import tech from '../images/technologies/tech-windowsserverclient-d6653bdec5086a2dff2aaa93a8fdd5ed.png'
import eset from '../images/technologies/eset.png'
import anydesk from '../images/technologies/anydesk.png'
import google from '../images/technologies/tech-googleworkspace-f57cb6efe1e92d4a634c38f81f01a577.png'

import microsoft from '../images/technologies/microsoft.png'
import prometheus from '../images/technologies/tech-prometheus-a7dcee35e78dc1d81752ef09b419760c.png'
import grafana from '../images/technologies/tech-grafana-8a1edfed75528afa4fd2f82c7c73c299.png'
import egn from '../images/technologies/egn.png'
import sav from '../images/technologies/acronis.png'

import tplink from '../images/technologies/tplink.png'
import linux from '../images/technologies/linux.png'
import checkpoint from '../images/technologies/checkpoint.png'
import paloalto from '../images/technologies/paloatlo.png'
import bitdefender from '../images/technologies/bitdefender.png'
import quantam from '../images/technologies/quantam.png'
import strata from '../images/technologies/strata.png'
import hyper from '../images/technologies/hyper.png'
import vbox from '../images/technologies/vbox.png'
import ansible from '../images/technologies/ansible.png'




import TechnomedicsLogo from '../images/clients/technomedics-logo.png'






import datwyler from '../images/technologies/datwyler.png'
import extreme from '../images/technologies/extreme.png'

import dellemc from '../images/technologies/dellemc.png'
import splunk from '../images/technologies/splunk.png'

import schneider from '../images/technologies/schneider.png'
import nessus from '../images/technologies/nessus.png'
import timik from '../images/technologies/timik.png'
import apple from '../images/technologies/apple.png'


import nmap from '../images/technologies/nmap.png'
import wireshark from '../images/technologies/wireshark.png'
import securecrt from '../images/technologies/securecrt.png'
import metasploit from '../images/technologies/metasploit.png'


import snort from '../images/technologies/snort.png'
import ossec from '../images/technologies/ossec.png'
import securityonion from '../images/technologies/security_onion.png'
import pfsense from '../images/technologies/pfsense.png'
import kismet from '../images/technologies/kismet-web.png'

const clients = [
    {
        name: 'Less',
        logo: less,
        url: 'https://lesscss.org/'
    },
    {
        name: 'Sass',
        logo: sass,
        url: 'https://sass-lang.com/'
    },
    {
        name: 'Foundation',
        logo: foundation,
        url: 'https://get.foundation/'
    },
    {
        name: 'Bootstrap',
        logo: bootstrap,
        url: 'https://getbootstrap.com/'
    },
    {
        name: 'Vuejs',
        logo: vuejs,
        url: 'https://vuejs.org/'
    },
    {
        name: 'React',
        logo: react,
        url: 'https://react.dev/'
    },
    {
        name: 'Jquery',
        logo: jquery,
        url: 'https://jquery.com/'
    },
    {
        name: 'Python',
        logo: python,
        url: 'https://www.python.org/'
    },
    {
        name: 'Php',
        logo: php,
        url: 'https://www.php.net/'
    },
    {
        name: 'Flask',
        logo: flask,
        url: 'https://flask.palletsprojects.com/'
    },
    {
        name: 'Nodejs',
        logo: nodejs,
        url: 'https://nodejs.org/en'
    },
    {
        name: 'Laravel',
        logo: laravel,
        url: 'https://laravel.com/'
    },
    {
        name: 'Flutter',
        logo: flutter,
        url: 'https://flutter.dev/'
    },
    {
        name: 'Reactnative',
        logo: reactnative,
        url: 'https://reactnative.dev/'
    },
    {
        name: 'MySQL',
        logo: mysql,
        url: 'https://www.mysql.com/'
    },
    {
        name: 'Mongodb',
        logo: mongodb,
        url: 'https://www.mongodb.com/'
    },
    {
        name: 'Mariadb',
        logo: mariadb,
        url: 'https://mariadb.org/'
    },
    {
        name: 'postgresql',
        logo: postgresql,
        url: 'https://www.postgresql.org/'
    },
    {
        name: 'AWS RDS',
        logo: awsrds,
        url: 'https://aws.amazon.com/rds/'
    },
    {
        name: 'Redis',
        logo: redis,
        url: 'https://redis.io/'
    },
    {
        name: 'Sqlite',
        logo: sqlite,
        url: 'https://www.sqlite.org/'
    },
    {
        name: 'WooCommerce',
        logo: woocommerce,
        url: 'https://woo.com/'
    },
    {
        name: 'Bagisto',
        logo: bagisto,
        url: 'https://bagisto.com/en/'
    },
    {
        name: 'Opencart',
        logo: opencart,
        url: 'https://www.opencart.com/'
    },
    {
        name: 'Shopify',
        logo: shopify,
        url: 'https://www.shopify.com/'
    },
    {
        name: 'Strapi',
        logo: strapi,
        url: 'https://strapi.io/'
    },
    {
        name: 'Ghost',
        logo: ghost,
        url: 'https://ghost.org/'
    },
    {
        name: 'Directus',
        logo: directus,
        url: 'https://directus.io/'
    },
    {
        name: 'Aws',
        logo: aws,
        url: 'https://aws.amazon.com/'
    },
    {
        name: 'Azure',
        logo: azure,
        url: 'https://azure.microsoft.com/en-us/'
    },
    {
        name: 'GCP',
        logo: gcp,
        url: 'https://cloud.google.com/'
    },
    {
        name: 'Chatgpt',
        logo: chatgpt,
        url: 'https://chat.openai.com/'
    },
    {
        name: 'synology',
        logo: synology,
        url: 'https://www.synology.com/en-uk'
    },
    {
        name: 'cisco',
        logo: cisco,
        url: 'https://www.cisco.com/'
    },
    {
        name: 'fortinet',
        logo: fortinet,
        url: 'https://www.fortinet.com/'
    },
    {
        name: 'tech',
        logo: tech,
        url: 'https://docs.microsoft.com/en-us/windows-server/remote/remote-desktop-services/clients/remote-desktop-clients'
    },
    {
        name: 'eset',
        logo: eset,
        url: 'https://www.eset.com/lk/'
    },
    {
        name: 'anydesk',
        logo: anydesk,
        url: 'https://anydesk.com/en'
    },
    {
        name: 'google',
        logo: google,
        url: 'https://workspace.google.com/'
    },
    {
        name: 'microsoft',
        logo: microsoft,
        url: 'https://www.office.com/'
    },
    {
        name: 'prometheus',
        logo: prometheus,
        url: 'https://prometheus.io/'
    },
    {
        name: 'grafana',
        logo: grafana,
        url: 'https://grafana.com/'
    },
    {
        name: 'egn',
        logo: egn,
        url: 'https://www.egnyte.com/'
    },
    {
        name: 'sav',
        logo: sav,
        url: 'https://www.acronis.com/'
    },
    {
        name: 'bitdefender',
        logo: bitdefender,
        url: 'https://www.bitdefender.com/'
    },
    {
        name: 'tplink',
        logo: tplink,
        url: 'https://www.tp-link.com/'
    },
    {
        name: 'checkpoint',
        logo: checkpoint,
        url: 'https://www.checkpoint.com/'
    },
    {
        name: 'quantam',
        logo: quantam,
        url: '#'
    },
    {
        name: 'paloalto',
        logo: paloalto,
        url: 'https://www.paloaltonetworks.com/'
    },
    {
        name: 'linux',
        logo: linux,
        url: 'https://www.linux.org/'
    },
    {
        name: 'strata',
        logo: strata,
        url: '#'
    },
    {
        name: 'hyper',
        logo: hyper,
        url: '#'
    },
    {
        name: 'vbox',
        logo: vbox,
        url: 'https://www.virtualbox.org/'
    },
    {
        name: 'ansible',
        logo: ansible,
        url: 'https://www.ansible.com/'
    },
    {
        name: 'Datwyler',
        logo: datwyler,
        url: 'https://datwyler.com/'
    },
    {
        name: 'Extreme',
        logo: extreme,
        url: 'https://www.extremenetworks.com/'
    },
    {
        name: 'DellEMC',
        logo: dellemc,
        url: 'https://www.dell.com/en-lk/blog/tags/dell-emc/'
    },
    {
        name: 'Splunk',
        logo: splunk,
        url: 'https://www.splunk.com/'
    },
    {
        name: 'Schneider',
        logo: schneider,
        url: 'https://www.se.com/lk/en/'
    },
    {
        name: 'Nessus',
        logo: nessus,
        url: 'https://www.tenable.com/products/nessus'
    },
    {
        name: 'Timik',
        logo: timik,
        url: 'https://timikenclosures.com/Timik/'
    },
    {
        name: 'Apple',
        logo: apple,
        url: 'https://www.apple.com/'
    },
    {
        name: 'Nmap',
        logo: nmap,
        url: 'https://nmap.org/'
    },
    {
        name: 'Wireshark',
        logo: wireshark,
        url: 'https://www.wireshark.org/'
    },
    {
        name: 'SecureCRT',
        logo: securecrt,
        url: 'https://www.vandyke.com/products/securecrt/'
    },
    {
        name: 'Metasploit',
        logo: metasploit,
        url: 'https://www.metasploit.com/'
    },
    {
        name: 'snort',
        logo: snort,
        url: 'https://www.snort.org/'
    },
    {
        name: 'kismet',
        logo: kismet,
        url: 'https://www.kismetwireless.net/'
    },
    {
        name: 'ossec',
        logo: ossec,
        url: 'https://www.ossec.net/'
    },
    {
        name: 'securityonionsolutions',
        logo: securityonion,
        url: 'https://securityonionsolutions.com/'
    },
    {
        name: 'pfsense',
        logo: pfsense,
        url: 'https://www.pfsense.org/'
    },
]

const Technologies = () => {
    return (
        <section  data-scrollax-parent="true" id="technologies">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Technologies<span>//</span></div>
            <div className="container" >
                <div className="section-title fl-wrap">
                    <h3>Technology Stack</h3>
                    <h2>TECHNOLOGY <span>CAPABILITIES</span></h2>
                 
                </div>
            </div>
        
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className='respimg' alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default Technologies
