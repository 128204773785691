import React from "react"
import HeroSlides from "../components/hero-slides"
import Layout from "../components/layout"
import HomeStats from "../components/home-stats"
import HomeSolutions from "../components/home-solutions"
import HomeAbout from "../components/home-about"
import Footer from "../components/footer"
import HomeWorkingProcess from "../components/home-working-process"
import HomeRecentProjects from "../components/home-recent-projects"
import HomeClientTestimonials from "../components/home-client-testimonials"
import SocialIconsFooter from "../components/social-icons-footer"
import Hometech from "../components/home-tech"
import HomeTechnologies from "../components/technologies"

const IndexPage = () => {
    return (
        <Layout
            pageTitle="PragICTS | Software Development, ITOps, CloudOps, CyberSecOps Company In Sri Lanka" og_desc="Based in Sri Lanka, we are an established provider of tailored IT services. Our expertise spans across software development, ITOps, CloudOps, CyberSecOps, eCommerce and Automation, IoTOps services.  Guided by a commitment to pragmatism and engineering excellence, we embrace a technology-agnostic approach, nurturing innovation while prioritizing practical solutions tailored to the distinct requirements of our esteemed clientele. Our proven track record underscores our steadfast dedication to engineering, delivery, support, and surpassing client expectations."
        >
            <div id="wrapper">
                <div className="scroll-nav-wrap fl-wrap">
                    <div className="scroll-down-wrap">
                        <div className="mousey">
                            <div className="scroller"></div>
                        </div>
                        <span>Scroll Down</span>
                    </div>
                    <nav className="scroll-nav scroll-init">
                        <ul>
                            {/* <li><a className="scroll-link act-link" href="#sec1">Hero</a></li> */}
                            <li><a className="scroll-link" href="#overview">Overview</a></li>
                            <li><a className="scroll-link" href="#stats">KPIs</a></li>
                            <li><a className="scroll-link" href="#solutions">Solutions</a></li>
                            <li><a className="scroll-link" href="#process">Process</a></li>
                            <li><a className="scroll-link" href="#projects">Projects</a></li>
                            <li><a className="scroll-link" href="#testimonials">Testimonials</a></li>
                            <li><a className="scroll-link" href="#technologies">Technology</a></li>
                            <li><a className="scroll-link" href="#partnership">Partnership</a></li>
                        </ul>
                    </nav>
                </div>

                <HeroSlides />

                <div className="content">

                    {/* overview section */}
                    <HomeAbout />
                    {/* numbers section */}
                    {/* <HomeCdn /> */}
                    <HomeStats />

                    {/* solutions  */}
                    <HomeSolutions />

                    {/* video presentation */}
                    {/* <HomeVideo /> */}

                    {/* our working process */}
                    <HomeWorkingProcess />

                    {/* featured projects */}
                    <HomeRecentProjects />

                    {/* client testimonials */}
                    <HomeClientTestimonials />
                    
                      {/* partnership */}
                      <HomeTechnologies />

                    {/* partnership */}
                    <Hometech />
                    

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default IndexPage
