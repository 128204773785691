import React, {useEffect, useRef} from "react"

const HomeWorkingProcess = () => {

    return (
        <section style={{ paddingTop: '0px' }} id="process" data-scrollax-parent="true">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>How We Engage</div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>How We Engage</h3>
                    <h2>Simplified   Engagement   <span>Process</span></h2>
                    <p>We streamline our engagement process to maximize effectiveness, pragmatism, and auditability. Furthermore, we customize this process to fit the unique context of each client, their specific requirements, and the KPIs essential for successful delivery. </p>
                </div>
                <div className="process-wrap fl-wrap">
                    <ul>
                        <li>
                            <div className="time-line-icon">
                                <i className="fab pragicts-analysis-icon"></i>
                            </div>
                            <h4>Familiarization</h4>
                            <div className="process-details">
                                {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                                <p>We begin by thoroughly understanding the issues, requirements, business objectives, and scope involved. Based on this comprehensive understanding, we then present a proposition tailored to address these factors effectively. If the situation does not align with our capabilities or strategic focus, we will respectfully decline.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">01.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-requirements-articulation-icon"></i>
                            </div>
                            <h4>Requirements</h4>
                            <div className="process-details">
                                {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                                <p>We employ a systematic approach to elicit and articulate requirements, encompassing various aspects such as business objectives, technical specifications, constraints, and both current and future needs. Through thorough analysis and stakeholder engagement, we ensure a comprehensive understanding of the project's scope and objectives. This process allows us to accurately capture and articulate the multifaceted requirements essential for successful project implementation, while also considering potential future developments and scalability.

</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">02.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-design-icon"></i>
                            </div>
                            <h4>Engineering</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>We collaborate closely with our clients throughout the system development process to ensure alignment with their expectations and key performance indicators (KPIs). By maintaining open communication channels and regular feedback loops, we actively involve the client in decision-making and validation stages. This approach enables us to iteratively refine the development process and deliver solutions that precisely meet the client's requirements and performance metrics.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">03.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-support-icon"></i>
                            </div>
                            <h4>Support</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>We offer ongoing support to maintain and sustain the solution, ensuring consistent yield and optimal performance over time. Our support services encompass proactive monitoring, troubleshooting, updates, and continuous improvement initiatives. By providing timely assistance and addressing any emerging issues, we help clients maximize the longevity and effectiveness of their solutions, ultimately ensuring sustained performance and value.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">04.</span>
                        </li>
                    </ul>
                </div>
                {/* <div className="fl-wrap mar-top">
                    <div className="srv-link-text">
                        <h4>Need more info ? Visit my services page :  </h4>
                        <a href="services.html" className="btn float-btn flat-btn color-btn">My Services</a>
                    </div>
                </div> */}
            </div>
            <div className="bg-parallax-module" data-position-top="90"  data-position-left="30" data-scrollax="properties: { translateY: '-150px' }"></div>
            <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
            <div className="sec-lines">
                <div className="container full-height"><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div></div>
            </div>
        </section>
    )
}

export default HomeWorkingProcess
